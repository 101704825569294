<script>
  import Navbar from './lib/Navbar.svelte';
</script>

<Navbar />

<main>
  <div>This is the main content.</div>
  <section>
    <div>This is a section.</div>
  </section>
  <article>
    <div>This is an article.</div>
  </article>
  <aside>
    <div>This is an aside.</div>
  </aside>
</main>
<footer>
  <div>This is the footer.</div>
</footer>