<nav class="dark:bg-gradient-to-r dark:from-blue-900 dark:via-gray-800 dark:to-green-900 bg-gradient-to-r from-blue-500 via-gray-200 to-green-500 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <a href="/" class="flex items-center bg-gray-500 bg-opacity-50 rounded-full pr-8">
        <img width="100" height="100" src="/logo.png" class="h-16 dark:bg-transparent rounded-full" alt="Logo">
        <span class="self-center text-1xl font-semibold whitespace-nowrap dark:text-white">Ramon van Keulen</span>
    </a>
      <div class="flex md:order-2">
          <button data-collapse-toggle="navbar-sticky" on:click={toggleNavbar} type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-600 rounded-lg md:hidden hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
        </button>
      </div>
      <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
        <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-600 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 dark:border-gray-700">
          <li>
            <a href="/" class="block py-2 pl-3 pr-4 text-dark bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-400" aria-current="page">Home</a>
          </li>
          <li>
            <a href="/" class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-500 bg-opacity-50 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-400 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</a>
          </li>
          <li>
            <a href="/" class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-500 bg-opacity-50 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-400 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</a>
          </li>
          <li>
            <a href="/" class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-500 bg-opacity-50 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-400 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <script>
    // JavaScript code for toggling the navigation bar
    function toggleNavbar() {
      var navbar = document.getElementById('navbar-sticky');
        // Check if the navbar element exists
        if (navbar) {
        navbar.classList.toggle('hidden');
        }
    }
  </script>